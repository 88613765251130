<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.SETTINGS.USERS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['users-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingUser"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.email`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['users-update'])"
              :to="{ name: 'edit-user', params: { id: item.id } }"
            >
              {{ item.email }}</router-link
            >
            <template v-else>{{ item.email }}</template>
          </template>

          <template v-slot:[`item.firstName`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['users-update'])"
              :to="{ name: 'edit-user', params: { id: item.id } }"
            >
              {{ item.firstName }}
            </router-link>
          </template>
          <template v-slot:[`item.lastName`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['users-update'])"
              :to="{ name: 'edit-user', params: { id: item.id } }"
            >
              {{ item.lastName }}</router-link
            >
            <template v-else>{{ item.lastName }}</template>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-chip :color="getColor(item.active)" small dark pill>
              <v-avatar left>
                <v-icon>{{ iconIsActive(item.active) }}</v-icon>
              </v-avatar>
              {{ textIsActive(item.active) }}</v-chip
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['users-update'])"
              :to="{ name: 'edit-user', params: { id: item.id } }"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    fab
                    dark
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.EDIT") }}</span>
              </v-tooltip>
            </router-link>
            <template v-else>Няма права</template>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";

//User Module
import { FETCH_USERS } from "@/modules/user/store/user.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 30,
        totalItems: 100,
      },
      filters: {
        roleId: null,
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id",
        },
        { text: "Първо име", value: "firstName" },
        { text: "Фамилия", value: "lastName" },
        { text: "Имейл", value: "email" },
        { text: "Роля", value: "role" },
        { text: "Активен", value: "active" },
        {
          align: "right",
          text: "Опции",
          value: "actions",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  components: {
    FormSelect,
    SearchTools,
  },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Потребители", route: { name: "list-users" } },
      { title: "Списък потребители" },
    ]);
  },
  computed: {
    ...mapGetters(["getUsers", "isLoadingUser"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      let vm = this;

      return vm.headers;
    },
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = vm.transformUrlParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_USERS, apiParams)
        .then((data) => {
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found",
            });
            this.$router.push({ name: "list-users" });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "red";
      else return "primary";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Not Active";
      else return "Active";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    },
  },
};
</script>
